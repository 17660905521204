// extracted by mini-css-extract-plugin
export var card = "index-module--card--153d3";
export var cards = "index-module--cards--d2b6e";
export var equipe = "index-module--equipe--58e23";
export var imgContainer = "index-module--imgContainer--3b7bc";
export var number = "index-module--number--3dcdc";
export var textBlock = "index-module--textBlock--50cf9";
export var textBlock2 = "index-module--textBlock2--ad8fc";
export var title = "index-module--title--ea70d";
export var underline = "index-module--underline--43e9a";
export var video = "index-module--video--ff3e4";
export var videoSection = "index-module--videoSection--461b7";