import * as React from 'react';

import { motion } from 'framer-motion';
import {
    StaticQuery, graphql
} from 'gatsby';
import ReactMarkdown from 'react-markdown';

import NousRejoindreImg from '../../assets/images/nous-rejoindre.png';
import { Head as DefaultHead } from '../../components/head/head.component';
import { PageTopBlock } from '../../components/shared/page-top-block/page-top-block.component';
import { VideoBlock } from '../../components/shared/video-block/video-block.component';
import { seo } from '../../config/seo-config';
import {
    BannerProps,
    SimpleTextBlockProps
} from '../../models/cms-common';
import { isBrowser } from '../../utils/url-utils';
import * as styles from './index.module.css';

interface NousRejoindreProps {
    ref: React.RefObject<HTMLDivElement>;
    pageTitle: string;
    pageSubtitle: string;
    title1: string;
    description1: string;
    title2: string;
    description2: string;
    textBlocks: SimpleTextBlockProps[];
    videoTitle: string;
    videoLink: string;
}

const NousRejoindreEnhancer = (Component: React.ComponentType<NousRejoindreProps>) => {
    return (
        <StaticQuery<{ strapiNousRejoindre: NousRejoindreProps }>
            query={graphql`
				query NousRejoindreQuery {
					strapiNousRejoindre {
						pageTitle
                        pageSubtitle

                        title1
                        description1
                        
                        title2
                        description2
                        textBlocks {
                            description
                        }

                        videoTitle
                        videoLink
					}
				}
				`
            }
            render={data => <Component {...data.strapiNousRejoindre} />}
        />
    );
};

function NousRejoindrePage() {
    return NousRejoindreEnhancer(nousRejoindrePage);
}

const nousRejoindrePage: React.FC<NousRejoindreProps> = ({
    ref,
    pageTitle,
    pageSubtitle,
    title1,
    description1,
    title2,
    description2,
    textBlocks,
    videoTitle,
    videoLink
}) => {
    const [transparentMode, setTransparentMode] = React.useState(
        isBrowser() ? document.body.scrollTop < Math.round(window.innerHeight / 2) : false
    );
    const [currentBgColor, setCurrentBgColor] = React.useState<string>('var(--primary-blue)');
    const [darkMode, setDarkMode] = React.useState(true);

    const banner: BannerProps = {
        title: pageTitle,
        subtitle: pageSubtitle,
    };

    return (
        <PageTopBlock
            ref={ref}
            transparent={transparentMode} dark={darkMode} backgroundColor={currentBgColor}
            setCurrentBgColor={setCurrentBgColor}
            setTransparentMode={(val) => setTransparentMode(val)}
            setDarkMode={setDarkMode}
            banner={banner}
            scrollIntoElementId='equipe'
            bannerBackgroundClassName='NousRejoindre'
        >
            <motion.section
                id='equipe'
                className={styles.equipe}
                viewport={{ amount: 0.60 }}
                onViewportEnter={() => {
                    setCurrentBgColor('var(--primary-blue)');
                    setDarkMode(true);
                }}
                style={{ backgroundColor: 'var(--primary-blue)' }}
            >
                <div className={`d-none d-md-flex ${styles.imgContainer}`}>
                    <img
                        className='w-100'
                        src={NousRejoindreImg}
                        alt=''
                    />
                </div>
                <div className={styles.textBlock}>
                    <h2 className={styles.title}>{title1}</h2>
                    <div className={styles.underline}></div>
                    <div className='mt-5'>{description1}</div>
                </div>
                <div className={styles.textBlock2}>
                    <h2 className={styles.title}>{title2}</h2>
                    <div className={styles.underline}></div>
                    <div className='mt-5'>{description2}</div>
                </div>
                <div className={`mt-5 ${styles.cards}`}>{textBlocks.map((t, i) => {
                    return (
                        <div
                            key={t.description.replace(' ', '-')}
                            className={`py-4 ${styles.card}`}
                        >
                            <div className={styles.number}>{i + 1}</div>
                            <ReactMarkdown className='mt-3 mt-md-5 mx-4'>{t.description}</ReactMarkdown>
                        </div>
                    );
                })}</div>
            </motion.section>
            <motion.section
                className={`d-flex flex-column align-items-center ${styles.videoSection}`}
                viewport={{amount: 0.60}}
                onViewportEnter={() => {
                    setCurrentBgColor('var(--white)');
                    setDarkMode(false);
                }}>
                <h2 className={`mt-5 ${styles.title}`}>{videoTitle}</h2>
                <VideoBlock
                    className={`w-75 mt-5 mb-5 ${styles.video}`}
                    videoTitle={videoTitle}
                    videoSrc={videoLink}
                />
            </motion.section>
        </PageTopBlock>
    );
};

export default NousRejoindrePage;

export const Head = () => <DefaultHead {...seo.nousRejoindre} />;
